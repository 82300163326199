// extracted by mini-css-extract-plugin
export var bgGifSVG = "styles-module--bgGifSVG--COEGa";
export var bgGifSVGAnimation1 = "styles-module--bgGifSVGAnimation1--1c7Uq";
export var bgGifSVGAnimation2 = "styles-module--bgGifSVGAnimation2--3zBwu";
export var bgGifSVGAnimation3 = "styles-module--bgGifSVGAnimation3--2riQ-";
export var bgSVG = "styles-module--bgSVG--3Uu-V";
export var bgWrap = "styles-module--bgWrap--37d5u";
export var bottom = "styles-module--bottom--3ppgs";
export var heading = "styles-module--heading--2y8QI";
export var sectionWrap = "styles-module--sectionWrap--3hdov";
export var top = "styles-module--top--1w00C";