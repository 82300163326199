import {StyleExtractor, isFunction} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import Slider from "react-slick";

// import HomeDesignElement from "../../../../assets/HomeDesignElement.svg";
import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import SanityRichText from "../../../commons/SanityRichText";
import {ArrowRightIcon} from "../../../icons/Arrows";
import {Stars} from "../../../icons/Stars";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const Card = ({
  image: {
    alt,
    image: {
      asset: {gatsbyImageData},
    },
  },
  hoveredImage,
  _rawHeading,
  subHeading,
  description,
}) => {
  return (
    <div
      className={styler.get("cardWrapper")}
      onMouseEnter={() => {
        if (isFunction(window?.gtag)) {
          window?.gtag("event", "sales-zoom", {
            description,
          });
        }
      }}
    >
      <div className={styler.get(["card", "org"])}>
        <GatsbyImage
          image={gatsbyImageData}
          objectFit='contain'
          alt={alt}
          className={styler.get("icon")}
        />
        <div className={styler.get("title")}>
          <SanityRichText blocks={_rawHeading} className='text-center' />
        </div>
      </div>
      <div className={styler.get(["card", "active"])}>
        <GatsbyImage
          image={hoveredImage?.image?.asset?.gatsbyImageData}
          objectFit='contain'
          alt={hoveredImage?.alt}
          className={styler.get("icon")}
        />
        <div className={styler.get("title")}>
          <SanityRichText blocks={_rawHeading} className='text-center' />
        </div>
        <div className={styler.get("subTitle")}>{subHeading}</div>
        <span className={styler.get("desc")}>
          <Stars className={styler.get("stars")} /> <span>“{description}”</span>
        </span>
      </div>
    </div>
  );
};

const MMV3Section2 = ({data}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");

  const {_rawHeading, _rawDescription, cards: cardsData = []} = data ?? {};

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <ArrowRightIcon color='#CCCFD4' />,
    prevArrow: <ArrowRightIcon color='#CCCFD4' />,
  };

  return (
    <section
      className={styler.get("sectionWrap", "full-bleed pb-12 px-4 md:px-0")}
    >
      {/* <HomeDesignElement className={styler.get(["designElement", "top"])} /> */}
      <h2 className={styler.get("heading")}>
        <SanityRichText blocks={_rawHeading} className='text-center' />
      </h2>
      <div className={styler.get("description")}>
        <SanityRichText blocks={_rawDescription} className='text-center' />
      </div>
      <div className={styler.get("cards")}>
        {!isMobile ? (
          cardsData.map((data) => <Card {...data} key={data._key} />)
        ) : (
          <Slider {...settings}>
            {cardsData.map((data) => (
              <Card {...data} key={data._key} />
            ))}
          </Slider>
        )}
      </div>
      {/* <HomeDesignElement className={styler.get(["designElement", "bottom"])} /> */}
    </section>
  );
};

export default MMV3Section2;
