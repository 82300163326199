import {StyleExtractor} from "@kubric/utils";
import React from "react";

import * as styles from "./styles.module.scss";

const MMV3VideoSection = ({data: video = {}, videoHeadline}) => {
  const styler = new StyleExtractor(styles);
  return (
    <section className={styler.get("sectionWrap")}>
      <h3>{videoHeadline}</h3>
      <video autoPlay muted loop>
        <source src={video?.asset?.url} type='video/webm' />
        Your browser does not support the video tag.
      </video>
    </section>
  );
};

export default MMV3VideoSection;
