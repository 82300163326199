// extracted by mini-css-extract-plugin
export var active = "styles-module--active--Ibs7E";
export var bottom = "styles-module--bottom--2di46";
export var card = "styles-module--card--Nf7zr";
export var cardWrapper = "styles-module--cardWrapper--1Yhz7";
export var cards = "styles-module--cards--1YS7-";
export var desc = "styles-module--desc--27irV";
export var description = "styles-module--description--1nhV2";
export var designElement = "styles-module--designElement--3hhM0";
export var heading = "styles-module--heading--2wi6g";
export var icon = "styles-module--icon--1RlA_";
export var org = "styles-module--org--3JnII";
export var sectionWrap = "styles-module--sectionWrap--3Y8WU";
export var stars = "styles-module--stars--2GOHA";
export var subTitle = "styles-module--subTitle--2oSFg";
export var title = "styles-module--title--3hZce";
export var top = "styles-module--top--1AnIE";