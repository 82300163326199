import * as React from "react";

export const Stars = ({className, onClick, size = 110}) => (
  <svg
    width={size}
    viewBox='0 0 110 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      d='M14.1889 4.56359L9.79328 3.97231L7.82831 0.28523C7.77464 0.18428 7.68635 0.102558 7.57728 0.0528845C7.30375 -0.0721012 6.97135 0.0320536 6.83458 0.28523L4.86962 3.97231L0.473988 4.56359C0.352801 4.57961 0.242001 4.63249 0.15717 4.71261C0.054614 4.81017 -0.00189902 4.94143 4.87275e-05 5.07754C0.00199647 5.21364 0.0622456 5.34347 0.167557 5.43849L3.34785 8.30835L2.59649 12.3608C2.57887 12.455 2.59014 12.552 2.62903 12.6406C2.66791 12.7293 2.73285 12.8061 2.81648 12.8623C2.90011 12.9185 2.99909 12.9519 3.10219 12.9587C3.20529 12.9655 3.30839 12.9454 3.39979 12.9008L7.33145 10.9875L11.2631 12.9008C11.3704 12.9536 11.4951 12.9713 11.6145 12.952C11.9158 12.904 12.1183 12.6396 12.0664 12.3608L11.315 8.30835L14.4953 5.43849C14.5819 5.35997 14.639 5.25742 14.6563 5.14525C14.7031 4.86483 14.4919 4.60525 14.1889 4.56359Z'
      fill='url(#paint0_linear_1012_876)'
    />
    <path
      d='M61.4359 4.56359L57.0402 3.97231L55.0753 0.28523C55.0216 0.18428 54.9333 0.102558 54.8242 0.0528845C54.5507 -0.0721012 54.2183 0.0320536 54.0815 0.28523L52.1166 3.97231L47.7209 4.56359C47.5997 4.57961 47.4889 4.63249 47.4041 4.71261C47.3016 4.81017 47.245 4.94143 47.247 5.07754C47.2489 5.21364 47.3092 5.34347 47.4145 5.43849L50.5948 8.30835L49.8434 12.3608C49.8258 12.455 49.8371 12.552 49.876 12.6406C49.9149 12.7293 49.9798 12.8061 50.0634 12.8623C50.1471 12.9185 50.246 12.9519 50.3491 12.9587C50.4522 12.9655 50.5553 12.9454 50.6467 12.9008L54.5784 10.9875L58.51 12.9008C58.6174 12.9536 58.742 12.9713 58.8615 12.952C59.1627 12.904 59.3653 12.6396 59.3133 12.3608L58.562 8.30835L61.7423 5.43849C61.8288 5.35997 61.886 5.25742 61.9033 5.14525C61.95 4.86483 61.7388 4.60525 61.4359 4.56359Z'
      fill='url(#paint1_linear_1012_876)'
    />
    <path
      d='M37.8124 4.56359L33.4168 3.97231L31.4518 0.28523C31.3982 0.18428 31.3099 0.102558 31.2008 0.0528845C30.9273 -0.0721012 30.5949 0.0320536 30.4581 0.28523L28.4932 3.97231L24.0975 4.56359C23.9763 4.57961 23.8655 4.63249 23.7807 4.71261C23.6781 4.81017 23.6216 4.94143 23.6236 5.07754C23.6255 5.21364 23.6858 5.34347 23.7911 5.43849L26.9714 8.30835L26.22 12.3608C26.2024 12.455 26.2137 12.552 26.2526 12.6406C26.2914 12.7293 26.3564 12.8061 26.44 12.8623C26.5236 12.9185 26.6226 12.9519 26.7257 12.9587C26.8288 12.9655 26.9319 12.9454 27.0233 12.9008L30.955 10.9875L34.8866 12.9008C34.994 12.9536 35.1186 12.9713 35.2381 12.952C35.5393 12.904 35.7419 12.6396 35.6899 12.3608L34.9386 8.30835L38.1189 5.43849C38.2054 5.35997 38.2626 5.25742 38.2799 5.14525C38.3266 4.86483 38.1154 4.60525 37.8124 4.56359Z'
      fill='url(#paint2_linear_1012_876)'
    />
    <path
      d='M85.0593 4.56359L80.6636 3.97231L78.6987 0.28523C78.645 0.18428 78.5567 0.102558 78.4476 0.0528845C78.1741 -0.0721012 77.8417 0.0320536 77.7049 0.28523L75.74 3.97231L71.3443 4.56359C71.2232 4.57961 71.1124 4.63249 71.0275 4.71261C70.925 4.81017 70.8685 4.94143 70.8704 5.07754C70.8724 5.21364 70.9326 5.34347 71.0379 5.43849L74.2182 8.30835L73.4669 12.3608C73.4492 12.455 73.4605 12.552 73.4994 12.6406C73.5383 12.7293 73.6032 12.8061 73.6868 12.8623C73.7705 12.9185 73.8695 12.9519 73.9726 12.9587C74.0757 12.9655 74.1787 12.9454 74.2702 12.9008L78.2018 10.9875L82.1335 12.9008C82.2408 12.9536 82.3655 12.9713 82.4849 12.952C82.7861 12.904 82.9887 12.6396 82.9368 12.3608L82.1854 8.30835L85.3657 5.43849C85.4523 5.35997 85.5094 5.25742 85.5267 5.14525C85.5734 4.86483 85.3622 4.60525 85.0593 4.56359Z'
      fill='url(#paint3_linear_1012_876)'
    />
    <path
      d='M108.683 4.56359L104.287 3.97231L102.322 0.28523C102.269 0.18428 102.18 0.102558 102.071 0.0528845C101.798 -0.0721012 101.465 0.0320536 101.328 0.28523L99.3635 3.97231L94.9679 4.56359C94.8467 4.57961 94.7359 4.63249 94.6511 4.71261C94.5485 4.81017 94.492 4.94143 94.4939 5.07754C94.4959 5.21364 94.5561 5.34347 94.6615 5.43849L97.8417 8.30835L97.0904 12.3608C97.0728 12.455 97.084 12.552 97.1229 12.6406C97.1618 12.7293 97.2267 12.8061 97.3104 12.8623C97.394 12.9185 97.493 12.9519 97.5961 12.9587C97.6992 12.9655 97.8023 12.9454 97.8937 12.9008L101.825 10.9875L105.757 12.9008C105.864 12.9536 105.989 12.9713 106.108 12.952C106.41 12.904 106.612 12.6396 106.56 12.3608L105.809 8.30835L108.989 5.43849C109.076 5.35997 109.133 5.25742 109.15 5.14525C109.197 4.86483 108.986 4.60525 108.683 4.56359Z'
      fill='url(#paint4_linear_1012_876)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_1012_876'
        x1='1.28526'
        y1='-8.748'
        x2='10.2281'
        y2='-8.06618'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.0667754' stopColor='#FFCB46' />
        <stop offset='0.297665' stopColor='#FFDE88' />
        <stop offset='0.36416' stopColor='#FFE091' />
        <stop offset='0.650093' stopColor='#FFCB44' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1012_876'
        x1='48.5322'
        y1='-8.748'
        x2='57.475'
        y2='-8.06618'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.0667754' stopColor='#FFCB46' />
        <stop offset='0.297665' stopColor='#FFDE88' />
        <stop offset='0.36416' stopColor='#FFE091' />
        <stop offset='0.650093' stopColor='#FFCB44' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1012_876'
        x1='24.9088'
        y1='-8.748'
        x2='33.8516'
        y2='-8.06618'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.0667754' stopColor='#FFCB46' />
        <stop offset='0.297665' stopColor='#FFDE88' />
        <stop offset='0.36416' stopColor='#FFE091' />
        <stop offset='0.650093' stopColor='#FFCB44' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_1012_876'
        x1='72.1556'
        y1='-8.748'
        x2='81.0984'
        y2='-8.06618'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.0667754' stopColor='#FFCB46' />
        <stop offset='0.297665' stopColor='#FFDE88' />
        <stop offset='0.36416' stopColor='#FFE091' />
        <stop offset='0.650093' stopColor='#FFCB44' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_1012_876'
        x1='95.7792'
        y1='-8.748'
        x2='104.722'
        y2='-8.06618'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.0667754' stopColor='#FFCB46' />
        <stop offset='0.297665' stopColor='#FFDE88' />
        <stop offset='0.36416' stopColor='#FFE091' />
        <stop offset='0.650093' stopColor='#FFCB44' />
      </linearGradient>
    </defs>
  </svg>
);
