import {StyleExtractor} from "@kubric/utils";
import React from "react";

import SanityRichText from "../../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const TopBGSVG = ({className}) => (
  <svg
    width='160'
    height='160'
    viewBox='0 0 160 160'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M58.3878 117.41L124.177 50.269C125.378 49.0433 124.579 46.9735 122.866 46.8727L101.47 45.6142L117.623 25.0955C118.614 23.8369 117.797 21.9822 116.199 21.8639L91.6495 20.0454C90.9371 19.9926 90.2506 20.3238 89.8486 20.9143L59.2898 65.7975C58.3657 67.1548 59.3806 68.9858 61.0214 68.9215L82.7141 68.0708L55.2334 114.999C54.0419 117.034 56.7376 119.094 58.3878 117.41Z'
      fill='#F2EA1F'
      stroke='black'
      strokeWidth='2'
    />
    <path
      d='M75.6466 135.918L134.314 106.097C135.539 105.474 135.479 103.703 134.214 103.165L122.657 98.2463L135.722 90.0495C136.82 89.3605 136.703 87.7245 135.518 87.1988L121.598 81.0239C121.07 80.7895 120.456 80.8547 119.989 81.1951L92.8374 100.969C91.7632 101.751 92.0373 103.423 93.3052 103.822L105.42 107.627L73.8931 133.218C72.3859 134.442 73.9163 136.797 75.6466 135.918ZM122.038 98.6344L122.039 98.6341L122.038 98.6344Z'
      fill='#FAB8C2'
      stroke='black'
      strokeWidth='2'
    />
    <path
      d='M44.9997 108.375L46.6106 57.3333C46.6544 55.9452 45.0445 55.1501 43.9688 56.0287L35.6095 62.8569L34.5823 49.5032L33.5853 49.5799L34.5823 49.5032C34.4829 48.2106 32.9801 47.5536 31.9637 48.3584L21.4662 56.6706C21.013 57.0295 20.7856 57.6029 20.8696 58.1749L25.1923 87.5876C25.3855 88.9024 26.9932 89.437 27.9353 88.4997L35.7332 80.741L41.8002 108.667C42.2054 110.532 44.9395 110.282 44.9997 108.375Z'
      fill='#BCE3DF'
      stroke='black'
      strokeWidth='2'
    />
  </svg>
);

const BottomBGSVG = ({className}) => (
  <svg
    width='217'
    height='96'
    viewBox='0 0 217 96'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M212.518 12.0451C208.636 26.5771 199.795 66.2515 140.472 50.4053C107.241 41.5289 108.554 10.742 119.895 6.74956C133.557 -1.26196 160.392 14.8971 136.585 58.377C112.779 101.857 68.8796 93.6908 49.9058 84.1727C32.5133 75.4478 11.2777 56.9448 4.11704 40.7923'
      stroke='#F6ED10'
      strokeWidth='8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const BottomBGSVGMobile = ({className}) => (
  <svg
    width='73'
    height='44'
    viewBox='0 0 73 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M69.9984 2C69.9984 7.32404 70.5936 21.6989 49.0627 21.6989C37.0019 21.6989 34.6609 11.0509 38.1414 8.64971C42.0449 4.66202 52.5979 7.73667 48.4675 24.78C44.337 41.8233 28.7264 43.0407 21.4374 41.519C14.7558 40.1241 5.88747 35.7364 2 30.8669'
      stroke='#F6ED10'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const MMV3MidSection = ({data, isMobile, sectionRef}) => {
  const styler = new StyleExtractor(styles);

  const {_rawText} = data ?? {};

  return (
    <section className={styler.get("sectionWrap")} ref={sectionRef}>
      <h2 className={styler.get("heading")}>
        <SanityRichText blocks={_rawText} className='text-center' />
      </h2>
      <div className={styler.get(["bgWrap", "top"])} />
      <div className={styler.get(["bgWrap", "bottom"])} />
      <TopBGSVG className={styler.get("bgGifSVG")} />
      {isMobile ? (
        <BottomBGSVGMobile className={styler.get(["bgSVG", "bottom"])} />
      ) : (
        <BottomBGSVG className={styler.get(["bgSVG", "bottom"])} />
      )}
    </section>
  );
};

export default MMV3MidSection;
