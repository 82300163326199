import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React, {useState} from "react";
import Slider from "react-slick";

import HomeDesignElement from "../../../../assets/HomeDesignElement.svg";
import SanityRichText from "../../../commons/SanityRichText";
import {ArrowRightIcon} from "../../../icons/Arrows";
import "./styles.scss";

const Card = ({heading, description, image}) => (
  <div className='sec3-card'>
    <GatsbyImage
      image={image?.image?.asset?.gatsbyImageData}
      objectFit='contain'
      alt={image?.alt}
    />
    <div className='sec3-card-info'>
      <span className='sec3-card-title'>{heading}</span>
      <span className='sec3-card-description'>{description}</span>
    </div>
  </div>
);

const MMV3Sec3 = ({data, isMobile}) => {
  const {
    _rawHeading,
    _rawDescription,
    items = [],
    show,
    gif,
    ctaUrl,
    ctaText,
  } = data ?? {};
  const [imgIndex, setImgIndex] = useState(0);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <ArrowRightIcon color='#CCCFD4' />,
    prevArrow: <ArrowRightIcon color='#CCCFD4' />,
  };
  return (
    <div className='sec3-wrap full-bleed px-2 md:px-0 my-8 md:my-0'>
      {/* <HomeDesignElement className='sec3-designElement svgTop' /> */}
      <h2 className='sec3-heading'>
        <SanityRichText blocks={_rawHeading} className='text-center' />
      </h2>
      <div className='sec3-description'>
        <SanityRichText blocks={_rawDescription} className='text-center' />
      </div>
      {show === "gif" ? (
        <GatsbyImage
          className='sec3-gif'
          image={gif?.image?.asset?.gatsbyImageData}
          alt={gif?.alt}
          style={{
            aspectRatio: `${gif?.image?.asset?.gatsbyImageData.width} / ${gif?.image?.asset?.gatsbyImageData.height}`,
          }}
        />
      ) : !isMobile ? (
        <div className='sec3-bg layouted'>
          <div className='sec3-content flex flex-row gap-14 items-start'>
            <div className='w-full md:w-2/4 flex flex-row justify-center items-center'>
              <GatsbyImage
                style={{borderRadius: 16, transition: "all 200ms linear"}}
                image={items[imgIndex]?.image?.image?.asset?.gatsbyImageData}
                alt={
                  items[imgIndex]?.image?.alt || items[imgIndex]?.image?.name
                }
              />
            </div>
            <div className='sec3-cardsWrapper'>
              <div className='flex flex-col sec3-cards'>
                {items.map(({description, heading, icon, _key}, i) => {
                  const active = i === imgIndex;
                  return (
                    <div
                      className={`sec3-card ${active ? "active" : ""}`}
                      onClick={() => setImgIndex(i)}
                      key={_key}
                    >
                      <GatsbyImage
                        image={icon?.image?.asset?.gatsbyImageData}
                        alt={icon?.alt || icon?.name}
                        className='sec3-card-icon'
                      />
                      <div className='sec3-card-info'>
                        <div className='sec3-card-title'>{heading}</div>
                        <div className='sec3-card-description'>
                          {description}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {ctaText && ctaUrl && (
                <OutboundLink
                  href={ctaUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='sec3-cta'
                  id={`learn-${ctaUrl}`}
                >
                  {ctaText}
                </OutboundLink>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='sec3-cards'>
            <Slider {...settings}>
              {items.map((data) => (
                <Card {...data} key={data._key} />
              ))}
            </Slider>
          </div>
          {ctaText && ctaUrl && (
            <OutboundLink
              href={ctaUrl}
              rel='noopener noreferrer'
              target='_blank'
              className='sec3-cta'
            >
              ctaText
            </OutboundLink>
          )}
        </>
      )}
    </div>
  );
};

export default MMV3Sec3;
